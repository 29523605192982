import { type Config } from 'tailwindcss'

export const extendedTheme = {
	colors: {
		border: 'hsl(var(--border) / <alpha-value>)',
		input: {
			DEFAULT: 'hsl(var(--input) / <alpha-value>)',
			invalid: 'hsl(var(--input-invalid) / <alpha-value>)',
		},
		ring: {
			DEFAULT: 'hsl(var(--ring) / <alpha-value>)',
			invalid: 'hsl(var(--foreground-destructive) / <alpha-value>)',
		},
		background: 'hsl(var(--background) / <alpha-value>)',
		foreground: {
			DEFAULT: 'hsl(var(--foreground) / <alpha-value>)',
			destructive: 'hsl(var(--foreground-destructive) / <alpha-value>)',
		},
		primary: {
			DEFAULT: 'hsl(var(--primary) / <alpha-value>)',
			foreground: 'hsl(var(--primary-foreground) / <alpha-value>)',
		},
		secondary: {
			DEFAULT: 'hsl(var(--secondary) / <alpha-value>)',
			foreground: 'hsl(var(--secondary-foreground) / <alpha-value>)',
		},
		destructive: {
			DEFAULT: 'hsl(var(--destructive) / <alpha-value>)',
			foreground: 'hsl(var(--destructive-foreground) / <alpha-value>)',
		},
		muted: {
			DEFAULT: 'hsl(var(--muted) / <alpha-value>)',
			foreground: 'hsl(var(--muted-foreground) / <alpha-value>)',
		},
		accent: {
			DEFAULT: 'hsl(var(--accent) / <alpha-value>)',
			foreground: 'hsl(var(--accent-foreground) / <alpha-value>)',
		},
		popover: {
			DEFAULT: 'hsl(var(--popover) / <alpha-value>)',
			foreground: 'hsl(var(--popover-foreground) / <alpha-value>)',
		},
		card: {
			DEFAULT: 'hsl(var(--card) / <alpha-value>)',
			foreground: 'hsl(var(--card-foreground) / <alpha-value>)',
		},
	},
	borderColor: {
		DEFAULT: 'hsl(var(--border) / <alpha-value>)',
	},
	borderRadius: {
		lg: 'var(--radius)',
		md: 'calc(var(--radius) - 2px)',
		sm: 'calc(var(--radius) - 4px)',
	},
	fontSize: {
		// Your font sizes remain the same
	},
	keyframes: {
		'caret-blink': {
			'0%,70%,100%': { opacity: '1' },
			'20%,50%': { opacity: '0' },
		},
	},
	animation: {
		'caret-blink': 'caret-blink 1.25s ease-out infinite',
	},
} satisfies Config['theme']
